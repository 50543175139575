import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navigation from "./components/Navigation";
import BatteryInfo from "./pages/BatteryInfo";
import BatteryManagement from "./pages/BatteryManagement";
import Range from "./pages/BatteryManagement";
import WarrantyCheck from "./pages/WarrantyCheck";
import CostCalculator from "./pages/CostCalculator";

function App() {
  return (
    <Router>
      <main className="min-h-screen bg-gray-50 antialiased">
        <Navigation />
        <Header />
        <div className="max-w-4xl mx-auto">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/battery-info" element={<BatteryInfo />} />
            <Route path="/battery-management" element={<BatteryManagement />} />
            <Route path="/warranty-check" element={<WarrantyCheck />} />
            <Route path="/cost-calculator" element={<CostCalculator />} />
            <Route path="/range" element={<Range />} />
          </Routes>
        </div>
        <Footer />
      </main>
    </Router>
  );
}

export default App;

import React, { useState, useRef, useEffect } from "react";
import { Menu, X } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";

const Navigation: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const navItemsRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLDivElement>(null);

  const isActive = (path: string) => location.pathname === path;

  const navItems = [
    { path: "/", label: "Home" },
    { path: "/battery-management", label: "Maximising battery" },
    { path: "/warranty-check", label: "Warranty checker" },
    { path: "/cost-calculator", label: "Cost per mile calculator" },
    //{ path: "/battery-info", label: "Battery Information" },
  ];

  useEffect(() => {
    const checkNavFit = () => {
      if (navRef.current && navItemsRef.current && logoRef.current) {
        const navWidth = navRef.current.offsetWidth;
        const navItemsWidth = navItemsRef.current.offsetWidth;
        const logoWidth = logoRef.current.offsetWidth;
        const totalRequiredWidth = navItemsWidth + logoWidth + 80; // Adding padding
        const needsCollapse = totalRequiredWidth > navWidth;

        setShouldCollapse(needsCollapse);
        // Close the mobile menu if we no longer need to collapse
        if (!needsCollapse) {
          setIsOpen(false);
        }
      }
    };

    checkNavFit();
    window.addEventListener("resize", checkNavFit);
    return () => window.removeEventListener("resize", checkNavFit);
  }, []);

  // Hidden measurement div to always know the full width needed
  const measurementDiv = (
    <div className="fixed -left-full invisible">
      <div className="flex space-x-8" ref={navItemsRef}>
        {navItems.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className="px-3 py-2 text-sm font-medium whitespace-nowrap"
          >
            {item.label}
          </Link>
        ))}
      </div>
    </div>
  );

  return (
    <nav className="bg-white w-full" ref={navRef}>
      {measurementDiv}
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex h-16 items-center justify-between">
            {/* Logo Section */}
            <div className="flex-shrink-0" ref={logoRef}>
              <Link to="/" className="flex items-center">
                <img
                  src={logo}
                  className="mt-1"
                  alt="Tesla battery check logo"
                  width="40"
                />
                <span className="ml-2 text-xl text-gray-900">
                  Tesla Battery Check
                </span>
              </Link>
            </div>

            {/* Hamburger Menu Button - Only shown when needed */}
            {shouldCollapse && (
              <div className="flex items-center">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                  aria-expanded={isOpen}
                  aria-label="Toggle navigation menu"
                >
                  {isOpen ? (
                    <X className="h-6 w-6" />
                  ) : (
                    <Menu className="h-6 w-6" />
                  )}
                </button>
              </div>
            )}

            {/* Navigation Items - Full width version */}
            {!shouldCollapse && (
              <div className="flex space-x-8">
                {navItems.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`${
                      isActive(item.path)
                        ? "text-blue-600 border-blue-600"
                        : "text-gray-600 hover:text-blue-500"
                    }  text-sm font-semibold uppercase whitespace-nowrap`}
                  >
                    {item.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Collapsible Menu - Only shown when needed */}
        {shouldCollapse && (
          <div
            className={`${
              isOpen ? "max-h-48" : "max-h-0"
            } overflow-hidden transition-all duration-300 ease-in-out`}
          >
            <div className="px-2 pt-2 pb-3 space-y-1 bg-white border-t">
              {navItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  onClick={() => setIsOpen(false)}
                  className={`${
                    isActive(item.path)
                      ? "bg-blue-50 text-blue-600"
                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                  } block px-3 py-2 rounded-md uppercase font-semibold`}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;

import React, { useState, useEffect } from "react";
import { InputField } from "./InputField";
import { ChevronDown, ChevronUp, X } from "lucide-react";
import energyScreen from "../assets/energy-screen.jpg";
import { Battery } from "lucide-react";
import { Link } from "react-router-dom";

interface VehicleDetailsProps {
  setWatthours: (value: number) => void;
  setMileage: (value: number) => void;
  setPercentage: (value: number) => void;
  capacity: number;
  capacityLostPercentage: number | null;
}

const Modal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}> = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "unset";
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div
        className="relative max-w-screen-lg w-full bg-white rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full text-gray-600"
          aria-label="Close modal"
        >
          <X size={24} />
        </button>
        {children}
        <div className="flex justify-center p-4 bg-gray-50 rounded-b-lg border-t">
          <button
            onClick={onClose}
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({
  setWatthours,
  setMileage,
  setPercentage,
  capacity,
  capacityLostPercentage,
}) => {
  const [showImage, setShowImage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="bg-blue-600 px-4 py-3">
        <h2 className="text-lg font-semibold text-white">
          Your vehicle details
        </h2>
      </div>
      <button
        onClick={() => setShowImage(!showImage)}
        className="flex items-center gap-2 text-blue-600 hover:text-blue-700 transition-colors p-4"
      >
        <span className="text-sm">Where can I find this data?</span>
        {showImage ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {showImage && (
        <div className="px-6 pb-6">
          <div
            className="border rounded-lg overflow-hidden cursor-pointer hover:opacity-90 transition-opacity"
            onClick={() => setIsModalOpen(true)}
          >
            <img
              src={energyScreen}
              alt="Vehicle diagram"
              className="w-full h-auto"
            />
          </div>
        </div>
      )}

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img
          src={energyScreen}
          alt="Vehicle diagram"
          className="w-full h-auto rounded-t-lg"
        />
      </Modal>

      <div className="p-4 pt-0 space-y-4">
        <InputField
          label="Wh/mi or Wh/km"
          name="wh"
          onChange={(value) => setWatthours(value)}
          placeholder="Enter watt hours"
        />
        <InputField
          label="Projected range"
          name="mileage"
          onChange={(value) => setMileage(value)}
          placeholder="Enter mileage"
        />
        <InputField
          label="Battery percentage"
          name="percentage"
          onChange={(value) => setPercentage(value)}
          placeholder="Enter percentage"
        />

        {(capacity || capacityLostPercentage) && (
          <div>
            {capacity && (
              <div className="bg-blue-50 mb-4 rounded-lg p-4 flex items-center space-x-4">
                <div className="bg-blue-100 p-4 rounded-full">
                  <Battery className="w-6 h-6 text-blue-600" />
                </div>
                <div>
                  <p className="text-sm text-blue-600 font-medium">
                    Battery Capacity
                  </p>
                  <p className="text-2xl font-bold text-blue-900">
                    {capacity.toFixed(2)}
                    <span className="text-base font-medium ml-1">kWh</span>
                  </p>
                </div>
              </div>
            )}

            {capacityLostPercentage && (
              <div className="bg-blue-50 rounded-lg p-4 mb-4 flex items-center space-x-4">
                <div className="bg-blue-100 p-4 rounded-full">
                  <svg
                    className="w-6 h-6 text-blue-600"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    <path d="M9 12l2 2 4-4" />
                  </svg>
                </div>
                <div>
                  <p className="text-sm text-blue-600 font-medium">
                    Capacity vs New
                  </p>
                  <p className="text-2xl font-bold text-blue-900">
                    {capacityLostPercentage.toFixed(1)}
                    <span className="text-base font-medium ml-1">%</span>
                  </p>
                </div>
              </div>
            )}

            <Link
              key={"/battery-management"}
              to={"/battery-management"}
              className="px-3 py-2 text-sm font-medium whitespace-nowrap"
            >
              How can I make the most of my battery?
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
